<template>
  <div>
    <div id="loginbox">
      <img src="@/assets/img/YonghuiSync/post.png" />

      <van-form class="lform" ref="form" @submit="subSuccess">
        <van-field
          class="lform-input key"
          placeholder="请输入订单详情中的卡密(或密码)"
          v-model="form.redeCodeNo"
          :rules="[
            {
              required: true,
              message: '请输入订单中的密码'
            }
          ]"
        />
        <van-field
          class="lform-input phone"
          placeholder="请输入您本人手机号码"
          v-model="form.phone"
          name="phone"
          :rules="[
            {
              required: true,
              validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
              message: '请填写正确的手机号'
            }
          ]"
        />
        <van-field
          class="lform-input yzm"
          placeholder="请输入验证码"
          v-model="form.code"
          :rules="[
            {
              required: true,
              message: '请输入验证码'
            }
          ]"
        >
          <template #button>
            <div class="cdown" v-if="!time" @click="sendCode">发送验证码</div>
            <van-count-down
              class="cdown"
              v-else
              :time="time"
              @finish="time = 0"
            />
          </template>
        </van-field>
        <van-button class="submit" :loading="loading" native-type="submit"
          >前往兑换</van-button
        >
      </van-form>
      <!-- 该链接内所有的说明以及样式均为电商运营部定制，虽然业务逻辑通用，但是行业合作部的业务属性不适用该页面样式，故需要更改页面内部分文字说明，并且生成新的链接供行业合作部使用 -->
      <!-- type:1 行业合作部 -->
      <div class="prompt important" v-show="![1].includes(+$route.query.type)">
        <div class="prompt-title">
          <img src="@/assets/img/YonghuiSync/prompt_icon.png" alt="" />
          <span class="text">防骗公告</span>
        </div>
        <div class="content">
          <p class="err">
            兑换后卡券不可退换、不可挂失，请妥善保管卡券信息，切勿发给他人，谨防泄露！
          </p>
          <p>
            本店没有小号旺旺，没有QQ、微信客服，从不刷信誉、做任务，从未委托任何机构或个人以“刷单返利”、“刷信誉”等方式购买商品，凡是要求提供手机验证码、远程共享屏幕都是为了骗你卡密，请广大消费者提高警惕，谨防上当受骗！
          </p>
        </div>
      </div>
      <div class="prompt">
        <div class="prompt-title">
          <img src="@/assets/img/YonghuiSync/tips.png" alt="" />
          <span class="text">兑换说明</span>
        </div>
        <div class="content">
          <p>
            1、接收验证码的手机号请填写您本人手机号，兑换前请确保页面所有信息填写正确，兑换后不支持退款！
          </p>
          <p v-if="[1].includes(+$route.query.type)">
            2、如遇订单量大或网络拥堵，券码出库可能会有所延迟，建议您耐心等待,若有疑问可联系4006627299。
          </p>
          <p v-else>
            2、如遇订单量大或网络拥堵，券码出库可能会有所延迟，建议您耐心等待若有疑问可咨询店铺在线客服。
          </p>
        </div>
      </div>
    </div>
    <div class="overlay" v-show="showDialog">
      <div class="center">
        <img src="@/assets/img/YonghuiSync/warm.png" />
        <h1>温馨提示</h1>
        <div>
          该兑换码已兑换，若需要查看已兑换的券码，请输入
          <span>首次兑换时的手机号码</span>
        </div>
        <button @click="showDialog = false">好的</button>
      </div>
    </div>
  </div>
</template>

<script>
import { syncVerify, sendVerifyCode } from '@/api/YonghuiSync.js'
export default {
  data() {
    return {
      time: 0,
      loading: false,
      showDialog: false,
      form: {
        redeCodeNo: undefined,
        phone: null,
        code: null
      }
    }
  },
  methods: {
    async subSuccess() {
      try {
        this.loading = true
        const { code, data, message } = await syncVerify({
          ...this.form,
          redeemCodePageType: 3,
          pageTypeMatchFlag: false
        })
        this.loading = false
        switch (code) {
          case 0:
            this.$router.push({
              path: '/SyncList',
              query: { ...data, type: this.$route.query.type }
            })
            break
          case 1:
            this.showDialog = true
            break
          case 2:
            this.$router.push({
              path: '/SyncOutStock'
            })
            break
          case 3:
            this.$router.push({
              path: '/SyncCrowded'
            })
            break
          default:
            this.$toast.fail({
              message: message
            })
            break
        }
      } catch (error) {
        this.loading = false
      }
    },
    sendCode() {
      this.$refs.form.validate('phone').then(async () => {
        const { code, message } = await sendVerifyCode({
          ...this.form,
          domain: window.location.hostname
        })
        if (code === 0) {
          this.time = 60000
        } else {
          this.$toast.fail({
            message: message
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/YonghuiSync.less';
</style>
